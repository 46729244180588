/* eslint-disable */
import ContentWrapper from 'Component/ContentWrapper';
import {
    SomethingWentWrongComponent as SourceSomethingWentWrongComponent,
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';

import './SomethingWentWrong.override.style.scss';

/** @namespace Scandipwa/Route/SomethingWentWrong/Component */
export class SomethingWentWrongComponent extends SourceSomethingWentWrongComponent {
    render() {
        const { onClick } = this.props;

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper label="Something went wrong on the page.">
                    <h1 style={{visibility: 'hidden'}}>Musique Dépôt</h1>
                    <p block="SomethingWentWrong" elem="Heading">{ __('Ooops!') }</p>
                    <h2 block="SomethingWentWrong" elem="SubHeading">{ __('Something went wrong!') }</h2>
                    { /* eslint-disable-next-line react/forbid-elements */ }
                    <a
                      href="/"
                      block="SomethingWentWrong"
                      elem="Button"
                      mix={ { block: 'Button' } }
                      onClick={ onClick }
                    >
                        { __('Back to homepage') }
                    </a>
                    { this.renderErrorDetails() }
                </ContentWrapper>
            </main>
        );
    }
}

export default SomethingWentWrongComponent;
