import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ChildrenType } from 'Type/Common.type';
import { initPayPal } from '../util/PayPal';

export const PaypalExpressContext = createContext({
    token: '',
    setToken: () => {},
    paypal: null,
    setPaypal: () => {}
});

PaypalExpressContext.displayName = 'PaypalExpressContext';

/** @namespace Scandiweb/PaypalExpress/Context/PaypalExpress/PaypalExpressProvider */
export const PaypalExpressProvider = ({ children }) => {
    const [token, setToken] = useState('');
    const [paypal, setPaypal] = useState(null);
    const [hasVisitedOtherPage, setHasVisitedOtherPage] = useState(false);
    const sdkUrl = useSelector((state) => state.ConfigReducer?.paypalConfigs?.sdkUrl);

    const initializePaypal = async () => {
        if (window.paypal) {
            setPaypal(window.paypal);
            return;
        }

        if (!sdkUrl) {
            return;
        }

        await initPayPal(sdkUrl);
        setPaypal(window.paypal);
    };

    useEffect(() => {
        const isHomepage = window.location.pathname === '/';

        if (isHomepage && !hasVisitedOtherPage) {
            return;
        }

        initializePaypal();

        if (isHomepage && !hasVisitedOtherPage) {
            setHasVisitedOtherPage(true);
        }

    }, [sdkUrl, hasVisitedOtherPage]);

    useEffect(() => {
        const handlePopState = () => {
            if (window.location.pathname !== '/' && !hasVisitedOtherPage) {
                setHasVisitedOtherPage(true);
                initializePaypal();
            }
        };

        window.addEventListener('popstate', handlePopState);

        const originalPushState = window.history.pushState;
        window.history.pushState = function (...args) {
            originalPushState.apply(this, args);
            handlePopState();
        };

        return () => {
            window.removeEventListener('popstate', handlePopState);
            window.history.pushState = originalPushState;
        };
    }, [hasVisitedOtherPage]);

    const value = {
        token,
        setToken,
        paypal,
        setPaypal
    };

    return (
        <PaypalExpressContext.Provider value={ value }>
            { children }
        </PaypalExpressContext.Provider>
    );
};

PaypalExpressProvider.displayName = 'PaypalExpressProvider';

PaypalExpressProvider.propTypes = {
    children: ChildrenType.isRequired
};
