Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/@scandiweb/stripe-integration/src/plugin/Store.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable max-len */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable no-multiple-empty-lines */
import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import CartReducer from 'Store/Cart/Cart.reducer';
import CategoryReducer from 'Store/Category/Category.reducer';
import CheckoutReducer from 'Store/Checkout/Checkout.reducer';
import CmsReducer from 'Store/Cms/Cms.reducer';
import ConfigReducer from 'Store/Config/Config.reducer';
import ContactFormReducer from 'Store/ContactForm/ContactForm.reducer';
import MetaReducer from 'Store/Meta/Meta.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import NavigationReducer from 'Store/Navigation/Navigation.reducer';
import NoMatchReducer from 'Store/NoMatch/NoMatch.reducer';
import OfflineReducer from 'Store/Offline/Offline.reducer';
import OverlayReducer from 'Store/Overlay/Overlay.reducer';
import PopupReducer from 'Store/Popup/Popup.reducer';
import ProductCompareReducer from 'Store/ProductCompare/ProductCompare.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import StoreInPickUpReducer from 'Store/StoreInPickUp/StoreInPickUp.reducer';
import UrlRewritesReducer from 'Store/UrlRewrites/UrlRewrites.reducer';
import WishlistReducer from 'Store/Wishlist/Wishlist.reducer';
import BrandTooltipReducer from '../../packages/@scandiweb/amasty-shop-by-brand/src/store/BrandTooltip/BrandTooltip.reducer';
import ProductLabelSettingReducer from '../../packages/@scandipwa/scandipwa-amasty-labels/src/store/ProductLabel/ProductLabelSetting/ProductLabelSetting.reducer';
import { ProductLabelProviderReducer } from
'../../packages/@scandipwa/scandipwa-amasty-labels/src/store/ProductLabel/ProductLabelProvider/ProductLabelProvider.reducer';
import { ProductLabelSelectedProductStateReducer } from
'../../packages/@scandipwa/scandipwa-amasty-labels/src/store/ProductLabel/ProductLabelSelectedProductState/ProductLabelSelectedProductState.reducer';


/** @namespace Scandipwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ProductListInfoReducer,
    CartReducer,
    WishlistReducer,
    NoMatchReducer,
    MyAccountReducer,
    NavigationReducer,
    OverlayReducer,
    OfflineReducer,
    PopupReducer,
    UrlRewritesReducer,
    ConfigReducer,
    MetaReducer,
    CheckoutReducer,
    ContactFormReducer,
    ProductCompareReducer,
    StoreInPickUpReducer,
    BreadcrumbsReducer,
    CategoryReducer,
    CmsReducer,
    BrandTooltipReducer,
    ProductLabelSettingReducer,
    ProductLabelProviderReducer,
    ProductLabelSelectedProductStateReducer
});
