/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';
import { updateMeta } from 'Store/Meta/Meta.action';

import {
    mapStateToProps,
    SomethingWentWrongContainer as SourceSomethingWentWrongContainer,
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.container';

export {
    mapStateToProps,
};

/** @namespace Scandipwa/Route/SomethingWentWrong/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
});

/** @namespace Scandipwa/Route/SomethingWentWrong/Container */
export class SomethingWentWrongContainer extends SourceSomethingWentWrongContainer {
    componentDidMount() {
        const { updateMeta } = this.props;

        const metaData = {
            title: "Musique Dépôt",
            meta_title: "Musique Dépôt",
            description: "Musique Dépôt | votre centre d'instruments de musique et école de musique de la rive-sud de Montréal. Pour nous les prix, le service et le partage de la connaissance est une priorité!",
            name: "Musique Dépôt",
        }

       updateMeta(metaData);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongContainer);
